import React from "react";

function XclusivesIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="32"
			height="32"
			fill="none"
			viewBox="0 0 32 32"
		>
			<g clipPath="url(#clip0_2990_53471)">
				<path
					fill="#9B0000"
					stroke="#fff"
					strokeWidth="0.3"
					d="M16.09 18.414l-.09-.069-.09.069-3.364 2.522 1.264-4.092.03-.097-.08-.064-3.332-2.666H14.643l.033-.104L16 9.821l1.324 4.092.033.104h4.21l-3.393 2.665-.081.064.03.098 1.262 4.086-3.294-2.516zm-12.563.267l.01-.002.01-.003a2.794 2.794 0 001.39-1.02A2.724 2.724 0 005.483 16c0-.61-.182-1.163-.546-1.656a2.795 2.795 0 00-1.39-1.02l-.01-.003-.01-.002a.862.862 0 01-.514-.284.78.78 0 01-.196-.535V8c0-.693.245-1.283.739-1.777a2.418 2.418 0 011.777-.74h21.334c.693 0 1.282.246 1.777.74.494.494.74 1.084.74 1.777v4.5a.78.78 0 01-.197.535.863.863 0 01-.514.284l-.01.002-.01.004a2.795 2.795 0 00-1.39 1.02A2.724 2.724 0 0026.517 16c0 .61.182 1.164.546 1.656.362.49.826.832 1.39 1.02l.01.003.01.002a.863.863 0 01.514.285.78.78 0 01.196.534V24c0 .693-.245 1.283-.74 1.777a2.418 2.418 0 01-1.777.74H5.334a2.418 2.418 0 01-1.777-.74A2.418 2.418 0 012.816 24v-4.5a.78.78 0 01.197-.534.863.863 0 01.514-.285zm1.656 5.32v.15H26.817V20.514l-.074-.044a5.316 5.316 0 01-1.897-1.897A4.979 4.979 0 0124.15 16c0-.93.232-1.786.696-2.574l-.13-.076.13.076a5.315 5.315 0 011.897-1.897l.074-.043V7.85H5.183v3.635l.074.044a5.316 5.316 0 011.897 1.897l.13-.076-.13.076c.464.788.696 1.645.696 2.574 0 .93-.232 1.786-.696 2.574a5.316 5.316 0 01-1.897 1.897l-.074.044V24z"
				/>
			</g>
			<defs>
				<clipPath id="clip0_2990_53471">
					<path fill="#fff" d="M0 0H32V32H0z" />
				</clipPath>
			</defs>
		</svg>
	);
}

export default XclusivesIcon;
