function AlertBellIcon(props) {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M13.1255 11.4461L12.2186 10.564L12.2186 7.14522C12.2186 5.04607 11.0656 3.28881 9.05498 2.82385V2.35889C9.05498 1.79137 8.58395 1.33325 8.00045 1.33325C7.41694 1.33325 6.94591 1.79137 6.94591 2.35889V2.82385C4.92824 3.28881 3.78232 5.03923 3.78232 7.14522V10.564L2.87542 11.4461C2.43252 11.8768 2.74185 12.6153 3.36754 12.6153L12.6263 12.6153C13.259 12.6153 13.5684 11.8768 13.1255 11.4461ZM10.8125 11.2478L5.18836 11.2478L5.18836 7.14522C5.18836 5.44949 6.24992 4.06829 8.00045 4.06829C9.75097 4.06829 10.8125 5.44949 10.8125 7.14522L10.8125 11.2478ZM8.00045 14.6666C8.77377 14.6666 9.40649 14.0512 9.40649 13.2991H6.5944C6.5944 14.0512 7.22009 14.6666 8.00045 14.6666Z"
				fill="#8C8594"
			/>
		</svg>
	);
}

export default AlertBellIcon;
