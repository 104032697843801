import { Input } from "@material-ui/core";
import styled from "styled-components";

export const StyledNav = styled.nav<{ isTransparent: boolean }>`
	display: block;
	background: ${({ isTransparent }) => (isTransparent ? "#F4F2F6" : "#fff")};
	width: 100%;
	padding: 15px 1rem;
	z-index: 20;
	position: ${({ isTransparent }) => (isTransparent ? "block" : "sticky")};
	top: -2px;
	transition: 300ms all;
	border-bottom: ${({ isTransparent }) =>
		isTransparent ? "none" : "1px solid #e4e4e4"};
	@media (max-width: 767px) {
		display: none;
	}
`;
export const StyledNavMobile = styled.nav<{ isDark: boolean; isTransparent?: boolean }>`
	display: none;
	// background: #4d3951;
	background: ${({ isTransparent }) => (isTransparent ? "#F4F2F6" : "#fff")};
	width: 100%;
	padding: 8px 16px;
	font-size: 1.5rem;
	position: ${({ isTransparent }) => (isTransparent ? "block" : "sticky")};
	top: 0;
	z-index: 20;
	@media (max-width: 767px) {
		display: block;
		border-bottom: ${({ isTransparent }) =>
			isTransparent ? "0 solid #e1e1e1" : "1px solid #e1e1e1"};
	}
`;
export const ApnaLogoContainer = styled.a`
	svg {
		height: 36px;
		width: 36px;
	}
`;
export const NavContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	color: #fff;

	@media (max-width: 767px) {
		height: 48px;
	}
`;
export const MenuContainer = styled.div`
	display: flex;
	justify-content: start;
	flex-grow: 1;
`;

export const IconsContainer = styled.div`
	display: flex;
	align-items: center;

	svg {
		cursor: pointer;
	}
`;

export const Flex = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 16px;
`;

export const StyledInput = styled(Input)`
	border: 1px solid #e8e7ea;
	border-radius: 8px;
	background: #f4f2f6;
	.Mui-disabled {
		color: #190a28;
	}
`;

export const Wrapper = styled.div``;
