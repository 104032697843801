function JobIcon(props) {
	return (
		<svg
			width="14"
			height="13"
			viewBox="0 0 14 13"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M5.66699 2.99992V1.66659H8.33366V2.99992H5.66699ZM12.3337 4.99992V10.9999C12.3337 11.3666 12.0337 11.6666 11.667 11.6666H2.33366C1.96699 11.6666 1.66699 11.3666 1.66699 10.9999V4.99992C1.66699 4.63325 1.96699 4.33325 2.33366 4.33325H11.667C12.0337 4.33325 12.3337 4.63325 12.3337 4.99992ZM1.66699 2.99992C0.926991 2.99992 0.333658 3.59325 0.333658 4.33325V11.6666C0.333658 12.4066 0.926991 12.9999 1.66699 12.9999H12.3337C13.0737 12.9999 13.667 12.4066 13.667 11.6666L13.6603 4.33325C13.6603 3.59325 13.0737 2.99992 12.3337 2.99992H9.66699V1.66659C9.66699 0.926585 9.07366 0.333252 8.33366 0.333252H5.66699C4.92699 0.333252 4.33366 0.926585 4.33366 1.66659V2.99992H1.66699Z"
				fill="#8C8594"
			/>
		</svg>
	);
}
export default JobIcon;
