/* eslint-disable react/jsx-props-no-spreading */
const LinkedinLogo = (props) => (
	<svg width={32} height={32} viewBox="0 0 32 32" {...props}>
		<path
			fill="#4D3951"
			d="M26.62 18.381v8.234h-4.848v-7.682c0-1.93-.701-3.247-2.456-3.247-1.34 0-2.137.887-2.488 1.746-.128.307-.16.734-.16 1.164v8.019h-4.85s.065-13.012 0-14.36h4.849v2.036c-.01.015-.023.031-.032.046h.032v-.046c.644-.977 1.795-2.373 4.37-2.373 3.191 0 5.583 2.052 5.583 6.463zM6.744 5.333C5.085 5.333 4 6.405 4 7.813c0 1.38 1.054 2.483 2.68 2.483h.032c1.692 0 2.743-1.104 2.743-2.482-.031-1.41-1.051-2.48-2.71-2.48zM4.288 26.615h4.848v-14.36H4.288v14.36z"
		/>
	</svg>
);

export default LinkedinLogo;
