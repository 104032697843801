function DownloadGrayIcon(props) {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M2.81514 8.74066V12.4444C2.81514 12.8518 3.14847 13.1851 3.55588 13.1851H12.4448C12.8522 13.1851 13.1855 12.8518 13.1855 12.4444V8.74066C13.1855 8.33325 13.5188 7.99992 13.9263 7.99992C14.3337 7.99992 14.667 8.33325 14.667 8.74066V13.1851C14.667 13.9999 14.0003 14.6666 13.1855 14.6666H2.81514C2.00032 14.6666 1.33366 13.9999 1.33366 13.1851V8.74066C1.33366 8.33325 1.66699 7.99992 2.0744 7.99992C2.48181 7.99992 2.81514 8.33325 2.81514 8.74066ZM7.25958 8.49622L5.86699 7.10362C5.5781 6.81473 5.11143 6.81473 4.82255 7.10362C4.53366 7.39251 4.53366 7.85918 4.82255 8.14807L7.48181 10.8073C7.7707 11.0962 8.23736 11.0962 8.52625 10.8073L11.1855 8.14807C11.4744 7.85918 11.4744 7.39251 11.1855 7.10362C10.8966 6.81473 10.43 6.81473 10.1411 7.10362L8.74107 8.49622V2.07399C8.74107 1.66659 8.40773 1.33325 8.00033 1.33325C7.59292 1.33325 7.25958 1.66659 7.25958 2.07399V8.49622Z"
				fill="#8C8594"
			/>
		</svg>
	);
}
export default DownloadGrayIcon;
