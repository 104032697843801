import config from "config";
import getTrackingParams from "./getTrackingParams";

const getEmployerLoginURL = () => {
	const params = getTrackingParams();

	if (params) {
		return `${config.dashboardUrl}/login${params}&employer=true`;
	}
	return `${config.dashboardUrl}/login?utm_source=Apna%20Main%20Website&utm_medium=Employer%20Login%20Button&utm_campaign=Homepage&employer=true`;
};

export default getEmployerLoginURL;
