import React from "react";

function HomeIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="16"
			height="16"
			fill="none"
			viewBox="0 0 16 16"
		>
			<path
				fill="#8C8594"
				fillRule="evenodd"
				d="M8.907 1.71a1.278 1.278 0 00-1.811 0l-.002.001-5.447 5.421c-.328.316-.37.756-.255 1.09.116.335.44.68.936.68h.442v4.378c0 .7.53 1.387 1.318 1.387h1.957c.788 0 1.319-.687 1.319-1.387v-2.936h1.275v2.936c0 .7.531 1.387 1.319 1.387h1.956c.788 0 1.32-.687 1.32-1.387V8.903h.441c.5 0 .821-.35.935-.685.112-.331.07-.768-.251-1.083L8.907 1.71zm-.906.98l4.904 4.88H11.9v5.71a.103.103 0 01-.014.054h-1.9a.103.103 0 01-.014-.054V9.01H6.03v4.27a.103.103 0 01-.013.054h-1.9a.103.103 0 01-.014-.054V7.57H3.097l4.904-4.88z"
				clipRule="evenodd"
			/>
		</svg>
	);
}

export default HomeIcon;
