import * as React from "react";

const ResumeBuilder = (props) => (
	<svg {...props} width="22" height="26" viewBox="0 0 22 26" fill="none">
		<path
			d="M21.5454 3.24C21.5454 1.56 20.1759 0.199997 18.4842 0.199997H3.66123C1.96949 0.199997 0.599976 1.56 0.599976 3.24V22.76C0.599976 24.44 1.96949 25.8 3.66123 25.8H18.4842C20.1759 25.8 21.5454 24.44 21.5454 22.76V3.24ZM19.9342 22.76C19.9342 23.56 19.2898 24.2 18.4842 24.2H3.66123C2.85564 24.2 2.21116 23.56 2.21116 22.76V3.24C2.21116 2.44 2.85564 1.8 3.66123 1.8H18.4842C19.2898 1.8 19.9342 2.44 19.9342 3.24V22.76Z"
			fill="#512194"
		/>
		<path
			d="M6.42698 14.5514H15.6873C15.9227 14.5514 16.1581 14.4714 16.3151 14.2316C16.472 14.0717 16.5505 13.8319 16.472 13.592C16.1581 11.6734 14.981 10.1544 13.4114 9.43491C13.8823 8.8753 14.1962 8.1558 14.1962 7.27641C14.1962 5.51763 12.7836 4.07863 11.0571 4.07863C9.33063 4.07863 7.91804 5.51763 7.91804 7.27641C7.91804 8.07585 8.23195 8.79535 8.70281 9.43491C7.13327 10.2344 5.87764 11.7533 5.64221 13.592C5.64221 13.8319 5.64221 14.0717 5.79917 14.2316C5.95612 14.4714 6.19155 14.5514 6.42698 14.5514ZM9.48758 7.35635C9.48758 6.47696 10.1939 5.75746 11.0571 5.75746C11.9204 5.75746 12.6267 6.47696 12.6267 7.35635C12.6267 8.23574 11.9204 8.95524 11.0571 8.95524C10.1939 8.95524 9.48758 8.23574 9.48758 7.35635ZM11.0571 10.5541C12.6267 10.5541 14.0392 11.5135 14.6671 12.9525H7.44718C8.075 11.5135 9.48758 10.5541 11.0571 10.5541Z"
			fill="#512194"
		/>
		<path
			d="M17.6455 17.2667H4.88855C4.41017 17.2667 4.09125 17.577 4.09125 18.0425C4.09125 18.5079 4.41017 18.8182 4.88855 18.8182H17.6455C18.1238 18.8182 18.4428 18.5079 18.4428 18.0425C18.4428 17.577 18.1238 17.2667 17.6455 17.2667Z"
			fill="#512194"
		/>
		<path
			d="M17.6455 20.3696H4.88855C4.41017 20.3696 4.09125 20.6799 4.09125 21.1454C4.09125 21.6109 4.41017 21.9212 4.88855 21.9212H17.6455C18.1238 21.9212 18.4428 21.6109 18.4428 21.1454C18.4428 20.6799 18.1238 20.3696 17.6455 20.3696Z"
			fill="#512194"
		/>
	</svg>
);
export default ResumeBuilder;
