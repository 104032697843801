/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from "react";
import { getUrlPath } from "utils/helpers";
import { InputAdornment, Modal } from "@material-ui/core";
import { Button as ThemedButton } from "components/ThemedComponent/Common";
import BackArrowIcon from "assets/svgs/BackArrowIcon";
import ApnaLogo from "assets/svgs/ApnaLogoV2";
import { isUserLoggedIn } from "utils";
import UserAvatar from "components/UserAvatar";
import {
	ApnaLogoContainer,
	Flex,
	NavContainer,
	StyledInput,
	StyledNavMobile,
} from "components/NavbarV2/NavbarStyle";
import OutlinedSearch from "assets/svgs/OutlinedSearch";
import dynamic from "next/dynamic";
import clsx from "clsx";
import useJobFeedStore from "components/RevampFeedDetails/JobFeed/store";
import { LoginRefactoredContext } from "../LoginRefactored/LoginContext";

const SearchComponent = dynamic(() => import("./Search"));

let SEARCH_OPTIONS = ["title", "company", "skill", "city", "area"];

export const getSearchInputString = (titleObj, experienceObj, cityObj) => {
	const keywordString = titleObj?.display_name
		? titleObj?.display_name
		: typeof titleObj === "string" && titleObj;
	const cityString =
		cityObj?.display_text_with_location ||
		cityObj?.display_text ||
		cityObj?.location?.name ||
		cityObj?.name;
	const experienceString = experienceObj?.display_text;

	let finalString = "";

	if (keywordString && cityString && experienceString) {
		finalString = ` ${keywordString}, ${experienceString}, ${cityString}`;
		if (finalString.length > 38) return `${finalString.substring(0, 38)}...`;
		return finalString;
	}
	if (keywordString && cityString && !experienceString) {
		finalString = `${keywordString}, ${cityString}`;
		if (finalString.length > 38) return `${finalString.substring(0, 38)}...`;
		return finalString;
	}
	if (experienceString && cityString && !keywordString) {
		finalString = `${experienceString}, ${cityString}`;
		if (finalString.length > 38) return `${finalString.substring(0, 38)}...`;
		return finalString;
	}
	if (keywordString && experienceString && !cityString) {
		finalString = `${keywordString}, ${experienceString}`;
		if (finalString.length > 38) return `${finalString.substring(0, 38)}...`;
		return finalString;
	}
	if (cityString && !keywordString && !experienceString) {
		return cityString;
	}
	if (keywordString && !cityString && !experienceString) {
		return keywordString;
	}

	return "";
};

function SearchComponentMobile({
	searchEntity,
	selectedCity,
	selectedArea,
	isShowSearchModal,
	setShowSearchModal,
	fromHomePage = false,
	appliedFilterCount = null,
}) {
	const { citySearchObject, titleSearchObject, experienceSearchObject } =
		useJobFeedStore();
	const [searchDisplayString, setSearchDisplayString] = useState("");
	const [searchOption, setSearchOption] = useState(0);
	const startAuth = useContext(LoginRefactoredContext);

	if (!fromHomePage) {
		SEARCH_OPTIONS = ["title", "company", "skill"];
	}

	useEffect(() => {
		const timer = setInterval(() => {
			setSearchOption((prev) => (prev + 1) % SEARCH_OPTIONS.length);
		}, 2000);

		return () => {
			clearInterval(timer);
		};
	}, []);

	useEffect(() => {
		setSearchDisplayString(
			getSearchInputString(
				titleSearchObject,
				experienceSearchObject,
				citySearchObject
			)
		);
	}, [citySearchObject, titleSearchObject, experienceSearchObject]);

	const handleCandidateLoginClick = () => {
		let screenType = "";
		const urlPath = getUrlPath();
		if (urlPath === "/") screenType = "HomePage";
		if (urlPath.includes("/jobs")) {
			screenType = "Job Listing Page";
		}
		if (urlPath.includes("/job/")) {
			screenType = "Job Detail Page";
		}
		startAuth();
		import("../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("Candidate login", {
				page: getUrlPath(),
				Section: "Header",
				actionType: "Redirection",
				cta: "Candidate Website Login",
				Screen: screenType,
			});
		});

		startAuth();
	};
	const handleOpen = () => {
		setShowSearchModal(true);
	};
	const handleClose = () => {
		setShowSearchModal(false);
	};

	return (
		<div
			className="flex w-full flex-col rounded-xl bg-white"
			// style={{ boxShadow: fromHomePage ? "0px 16px 32px 0px #0000000A" : "none" }}
		>
			<StyledInput
				onClick={handleOpen}
				readOnly
				disableUnderline
				className={clsx(
					"mediumPlaceholder grow truncate p-2 text-sm outline-none",
					{
						"m-[12px] px-[8px] py-[8px]": !fromHomePage,
					}
				)}
				placeholder={`Search jobs by '${SEARCH_OPTIONS[searchOption]}'`}
				startAdornment={
					<InputAdornment position="start">
						<OutlinedSearch />
					</InputAdornment>
				}
				value={searchDisplayString}
			/>
			<Modal
				open={isShowSearchModal}
				onClose={handleClose}
				aria-labelledby="child-modal-title"
				aria-describedby="child-modal-description"
			>
				<div className="h-[100vh] overflow-auto bg-white">
					<StyledNavMobile isDark={false}>
						<NavContainer>
							<Flex onClick={handleClose}>
								<BackArrowIcon fill="#333" />
								<ApnaLogoContainer>
									<ApnaLogo />
								</ApnaLogoContainer>
							</Flex>
							{isUserLoggedIn() ? (
								<UserAvatar isDark={false} />
							) : (
								<div onClick={handleCandidateLoginClick}>
									<ThemedButton
										className="!rounded-[4px] !text-xs !font-semibold"
										variant="primary"
										size="small"
									>
										Candidate Login
									</ThemedButton>
								</div>
							)}
						</NavContainer>
					</StyledNavMobile>
					<SearchComponent
						fromHomePage
						selectedCity={selectedCity}
						searchEntity={searchEntity}
						selectedArea={selectedArea}
						appliedFilterCount={appliedFilterCount}
						handleMobileClose={() => handleClose()}
					/>
				</div>
			</Modal>
		</div>
	);
}

export default SearchComponentMobile;
