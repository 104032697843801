function StarGrayIcon(props) {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M1.04242 4.79581L4.81259 4.45363L6.28482 0.828186C6.54967 0.168274 7.45326 0.168274 7.71811 0.828186L9.19034 4.46178L12.9527 4.79581C13.6382 4.85284 13.9186 5.74901 13.3967 6.22154L10.5379 8.81231L11.3948 12.6577C11.5506 13.3584 10.8262 13.9124 10.2341 13.5376L7.00147 11.5008L3.76878 13.5458C3.17677 13.9205 2.45234 13.3665 2.60813 12.6659L3.46499 8.81231L0.606205 6.22154C0.0843019 5.74901 0.356937 4.85284 1.04242 4.79581ZM7.00147 9.97734L9.93035 11.8267L9.15139 8.33978L11.7375 5.99343L8.3257 5.68384L7.00147 2.40057L5.66944 5.69198L2.2576 6.00157L4.84375 8.34793L4.06479 11.8349L7.00147 9.97734Z"
				fill="#8C8594"
			/>
		</svg>
	);
}
export default StarGrayIcon;
