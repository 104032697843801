import * as React from "react";

const ResumeAi = (props) => (
	<svg width="26" height="26" viewBox="0 0 26 26" fill="none" {...props}>
		<mask
			id="mask0_1961_25391"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="26"
			height="26"
		>
			<path d="M0.200012 0.199987H25.8V25.8H0.200012V0.199987Z" fill="white" />
		</mask>
		<g mask="url(#mask0_1961_25391)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M0.81175 21.5954C1.18205 21.7488 1.60828 21.664 1.8917 21.3806L5.49927 17.773H18.6981C20.34 17.773 21.671 16.442 21.671 14.8001V3.17277C21.671 1.53086 20.34 0.199873 18.6981 0.199873H3.17292C1.53106 0.199873 0.200012 1.53084 0.200012 3.17277V20.6798C0.200012 21.0806 0.441453 21.442 0.81175 21.5954ZM2.18195 18.2875V3.17277C2.18195 2.62546 2.62562 2.18181 3.17292 2.18181H18.6981C19.2454 2.18181 19.689 2.62545 19.689 3.17277V14.8001C19.689 15.3474 19.2454 15.7911 18.6981 15.7911H5.08881C4.82599 15.7911 4.57393 15.8955 4.38809 16.0813L2.18195 18.2875Z"
				fill="#004BA9"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.98035 6.80643C5.98035 7.26251 6.43001 7.63223 6.98471 7.63223H14.8857C15.4404 7.63223 15.89 7.26251 15.89 6.80643C15.89 6.35035 15.4404 5.98062 14.8857 5.98062H6.98471C6.43001 5.98062 5.98035 6.35035 5.98035 6.80643Z"
				fill="#004BA9"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M5.98035 10.9354C5.98035 11.3914 6.43001 11.7612 6.98471 11.7612H14.8857C15.4404 11.7612 15.89 11.3914 15.89 10.9354C15.89 10.4793 15.4404 10.1096 14.8857 10.1096H6.98471C6.43001 10.1096 5.98035 10.4793 5.98035 10.9354Z"
				fill="#004BA9"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.1881 25.7242C25.5584 25.5708 25.7999 25.2095 25.7999 24.8087V7.30159C25.7999 5.65967 24.4689 4.32869 22.827 4.32869H20.9111C20.3638 4.32869 19.9201 4.77236 19.9201 5.31966C19.9201 5.86695 20.3638 6.31062 20.9111 6.31062H22.827C23.3743 6.31062 23.8179 6.75426 23.8179 7.30159V22.4162L21.6118 20.2101C21.426 20.0243 21.1739 19.9199 20.9111 19.9199H7.30182C6.75452 19.9199 6.31085 19.4762 6.31085 18.9289V17.0131C6.31085 16.4658 5.86718 16.0221 5.31988 16.0221C4.77259 16.0221 4.32892 16.4658 4.32892 17.0131V18.9289C4.32892 20.5708 5.65997 21.9018 7.30182 21.9018H20.5006L24.1082 25.5094C24.3916 25.7928 24.8178 25.8776 25.1881 25.7242Z"
				fill="#004BA9"
			/>
		</g>
	</svg>
);
export default ResumeAi;
