function ContactIcon(props) {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
            {...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.58132 1.64701L4.79657 3.51746C4.84852 3.97022 4.69265 4.41556 4.37349 4.73473L3.00036 6.10787C4.06918 8.21583 5.79116 9.9304 7.89168 10.9992L9.2574 9.6335C9.57656 9.31434 10.0219 9.15847 10.4747 9.21043L12.3599 9.42568C13.1096 9.51475 13.6737 10.1456 13.6663 10.8953V12.1794C13.6663 13.0181 12.9686 13.7158 12.1298 13.6639C5.79858 13.2705 0.729119 8.20841 0.335734 1.86969C0.283778 1.03096 0.981479 0.333252 1.8202 0.333252H3.10427C3.86135 0.333252 4.49225 0.897354 4.58132 1.64701ZM10.3234 10.535L10.3227 10.5349C10.273 10.5292 10.2311 10.5453 10.2002 10.5762L9.08952 11.6869C10.0741 12.0426 11.1223 12.2652 12.2123 12.3329C12.2278 12.3338 12.2508 12.331 12.2809 12.3016C12.3139 12.2694 12.333 12.225 12.333 12.1792V10.8952V10.8885L12.333 10.8819C12.3336 10.8279 12.2917 10.7609 12.2042 10.7497L12.2026 10.7495L10.3234 10.535ZM3.43066 3.79192L2.31231 4.91027C1.95671 3.92587 1.73417 2.8775 1.66649 1.7871C1.66556 1.77162 1.66831 1.74877 1.69778 1.7186C1.73002 1.68559 1.77444 1.66659 1.82018 1.66659H3.10425C3.18193 1.66659 3.24685 1.72146 3.25713 1.80311L3.47194 3.66974C3.47754 3.71927 3.46148 3.7611 3.43066 3.79192Z"
				fill="#8C8594"
			/>
		</svg>
	);
}
export default ContactIcon;
