function LogoutRedIcon(props) {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8 3.33333C7.82319 3.33333 7.65362 3.40357 7.5286 3.5286C7.40357 3.65362 7.33333 3.82319 7.33333 4V5.33333C7.33333 5.70152 7.03486 6 6.66667 6C6.29848 6 6 5.70152 6 5.33333V4C6 3.46957 6.21071 2.96086 6.58579 2.58579C6.96086 2.21071 7.46957 2 8 2H12.6667C13.1971 2 13.7058 2.21071 14.0809 2.58579C14.456 2.96086 14.6667 3.46957 14.6667 4V12C14.6667 12.5304 14.456 13.0391 14.0809 13.4142C13.7058 13.7893 13.1971 14 12.6667 14H8C7.46957 14 6.96086 13.7893 6.58579 13.4142C6.21071 13.0391 6 12.5304 6 12V10.6667C6 10.2985 6.29848 10 6.66667 10C7.03486 10 7.33333 10.2985 7.33333 10.6667V12C7.33333 12.1768 7.40357 12.3464 7.5286 12.4714C7.65362 12.5964 7.82319 12.6667 8 12.6667H12.6667C12.8435 12.6667 13.013 12.5964 13.1381 12.4714C13.2631 12.3464 13.3333 12.1768 13.3333 12V4C13.3333 3.82319 13.2631 3.65362 13.1381 3.5286C13.013 3.40357 12.8435 3.33333 12.6667 3.33333H8Z"
				fill="#CB2F4B"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.4711 5.52851C4.73145 5.78886 4.73145 6.21097 4.4711 6.47132L3.60917 7.33325H9.99969C10.3679 7.33325 10.6664 7.63173 10.6664 7.99992C10.6664 8.36811 10.3679 8.66658 9.99969 8.66658H1.99969C1.73005 8.66658 1.48696 8.50416 1.38377 8.25504C1.28059 8.00592 1.33762 7.71918 1.52829 7.52851L3.52829 5.52851C3.78864 5.26816 4.21075 5.26816 4.4711 5.52851Z"
				fill="#CB2F4B"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.52827 7.52851C1.78862 7.26816 2.21073 7.26816 2.47108 7.52851L4.47108 9.52851C4.73143 9.78886 4.73143 10.211 4.47108 10.4713C4.21073 10.7317 3.78862 10.7317 3.52827 10.4713L1.52827 8.47132C1.26792 8.21097 1.26792 7.78886 1.52827 7.52851Z"
				fill="#CB2F4B"
			/>
		</svg>
	);
}
export default LogoutRedIcon;
