import React from "react";

function NavbarBackIcon() {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="24"
			height="24"
			fill="none"
			viewBox="0 0 24 24"
		>
			<path
				fill="#000"
				fillRule="evenodd"
				d="M3 12a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1z"
				clipRule="evenodd"
			/>
			<path
				fill="#000"
				fillRule="evenodd"
				d="M9.707 6.293a1 1 0 010 1.414L5.414 12l4.293 4.293a1 1 0 01-1.414 1.414l-5-5a1 1 0 010-1.414l5-5a1 1 0 011.414 0z"
				clipRule="evenodd"
			/>
		</svg>
	);
}

export default NavbarBackIcon;
