function LoggedinGrayUserIcon(props) {
	return (
		<svg
			width="12"
			height="12"
			viewBox="0 0 12 12"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				d="M6 1.425C5.13 1.425 4.425 2.13 4.425 3C4.425 3.87 5.13 4.575 6 4.575C6.87 4.575 7.575 3.87 7.575 3C7.575 2.13 6.87 1.425 6 1.425ZM6 8.175C3.7725 8.175 1.425 9.27 1.425 9.75V10.575H10.575V9.75C10.575 9.27 8.2275 8.175 6 8.175ZM6 0C7.6575 0 9 1.3425 9 3C9 4.6575 7.6575 6 6 6C4.3425 6 3 4.6575 3 3C3 1.3425 4.3425 0 6 0ZM6 6.75C8.0025 6.75 12 7.755 12 9.75V11.25C12 11.6625 11.6625 12 11.25 12H0.75C0.3375 12 0 11.6625 0 11.25V9.75C0 7.755 3.9975 6.75 6 6.75Z"
				fill="#8C8594"
			/>
		</svg>
	);
}
export default LoggedinGrayUserIcon;
