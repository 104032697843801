const CoverLetter = (props) => (
	<svg
		width="22"
		height="26"
		viewBox="0 0 22 26"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M21.3455 3.24001C21.3455 1.56001 19.976 0.200012 18.2842 0.200012H3.46128C1.76954 0.200012 0.400024 1.56001 0.400024 3.24001V22.76C0.400024 24.44 1.76954 25.8 3.46128 25.8H18.2842C19.976 25.8 21.3455 24.44 21.3455 22.76V3.24001ZM19.7343 22.76C19.7343 23.56 19.0898 24.2 18.2842 24.2H3.46128C2.65569 24.2 2.01121 23.56 2.01121 22.76V3.24001C2.01121 2.44001 2.65569 1.80001 3.46128 1.80001H18.2842C19.0898 1.80001 19.7343 2.44001 19.7343 3.24001V22.76Z"
			fill="#BD5B00"
		/>
		<path
			d="M17.4456 4.07852H4.68872C4.21034 4.07852 3.89142 4.38882 3.89142 4.85428C3.89142 5.31973 4.21034 5.63004 4.68872 5.63004H17.4456C17.924 5.63004 18.2429 5.31973 18.2429 4.85428C18.2429 4.38882 17.924 4.07852 17.4456 4.07852Z"
			fill="#BD5B00"
		/>
		<path
			d="M15.9803 7.18204H4.60253C4.17586 7.18204 3.89142 7.49234 3.89142 7.95779C3.89142 8.42325 4.17586 8.73355 4.60253 8.73355H15.9803C16.407 8.73355 16.6914 8.42325 16.6914 7.95779C16.6914 7.49234 16.407 7.18204 15.9803 7.18204Z"
			fill="#BD5B00"
		/>
		<path
			d="M17.4456 10.2848H4.68872C4.21034 10.2848 3.89142 10.5951 3.89142 11.0605C3.89142 11.526 4.21034 11.8363 4.68872 11.8363H17.4456C17.924 11.8363 18.2429 11.526 18.2429 11.0605C18.2429 10.5951 17.924 10.2848 17.4456 10.2848Z"
			fill="#BD5B00"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.66039 22.3092C8.38531 22.3092 8.16024 22.0951 8.16024 21.8335C8.11023 20.5015 8.61038 16.0061 12.2615 17.0289C12.4865 17.1002 12.6366 17.2905 12.6366 17.5046C12.4365 20.5491 13.912 19.8593 13.8119 18.5749C13.7869 18.2181 14.187 17.9327 14.5371 18.123C15.1873 18.456 16.1376 18.5749 16.4877 17.6473C16.7128 17.0527 17.6381 17.3857 17.438 17.9803C16.9379 19.2409 15.8125 19.4788 14.7872 19.2171C14.4871 20.8345 11.5863 21.7859 11.6113 17.8614C9.41062 17.5284 9.13553 20.7632 9.16054 21.8097C9.16054 22.0951 8.93547 22.3092 8.66039 22.3092Z"
			fill="#BD5B00"
		/>
	</svg>
);
export default CoverLetter;
