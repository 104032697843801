import CloseIcon from "assets/svgs/CloseIcon";
import styled from "styled-components";
import { getUrlPath } from "utils/helpers";
import source from "utils/source";
import { OverlayContainerType, OverlayPropTypes } from "../types";

const Overlay: React.FC<OverlayPropTypes> = ({ isSidebarOpen, closeSidebar }) => {
	const handleCloseButtonClick = (e) => {
		import("../../../utils/Mixpanel").then((Mixpanel) => {
			Mixpanel.default.track("Close Sidebar Click", {
				Source: source,
				page: getUrlPath(),
				Section: "Header Menu",
			});
		});
		closeSidebar(e);
	};

	return (
		<OverlayContainer onClick={handleCloseButtonClick} isSidebarOpen={isSidebarOpen}>
			{isSidebarOpen && (
				<CloseIcon
					onClick={closeSidebar}
					className="close-icon"
					bgColor="#fff"
					iconColor="#000"
				/>
			)}
		</OverlayContainer>
	);
};

export default Overlay;

const OverlayContainer = styled.div<OverlayContainerType>`
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    transform: ${({ isSidebarOpen }) =>
		isSidebarOpen ? "translate(0%);" : "translate(-100%);"}
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0,0,0,0.35);
    z-index: 22;
    
    @media(min-width: 767px){
        display: none;
    }

    .close-icon {
      position: absolute;
      top: 2.9%;
      right: 5%;
      width: 24px;
      height: 24px;
    }

`;
