import { isUserLoggedIn } from "utils";
import config from "config";

export const SIDEBAR_CAREER_COMPASS_DATA = [
	{
		page: "carrer-compass",
		link: [
			{
				label: "Resume Builder",
				title: "Resume Builder",
				url: `${config.websiteUrl}resume-builder`,
			},
			{
				label: "Cover Letter",
				title: "Cover Letter",
				url: `${config.websiteUrl}cover-letter`,
			},
			{
				label: "Resume Checker",
				title: "Resume Checker",
				url: `${config.websiteUrl}resume-checker`,
			},
			{
				label: "Blogs",
				title: "Blogs",
				url: `${config.websiteUrl}career-central/`,
			},
		],
	},
];

export const LOGIN_DATA = [
	{
		page: "login",
		link: [
			{
				label: "Candidate",
				title: "Candidate",
				url: `${config.websiteUrl}/?user_auth=phone_number`,
			},
			{
				label: "Employer",
				title: "Employer",
				url: `https://employer.apna.co/login`,
			},
		],
	},
];

const SIDEBAR_DATA = [
	{
		page: "Jobs By Type",
		links: [
			{
				label: "Work From Home Jobs",
				url: "/jobs/work_from_home-jobs",
				title: "Work From Home Jobs",
			},
			{
				label: "Part Time Jobs",
				url: "/jobs/part_time-jobs",
				title: "Part Time Jobs",
			},
			{
				label: "Freshers Jobs",
				url: "/jobs/freshers-jobs",
				title: "Freshers Jobs",
			},
			{
				label: "Women Jobs",
				url: "/jobs/female-jobs",
				title: "Women Jobs",
			},
			{
				label: "Full Time Jobs",
				url: "/jobs/full_time-jobs",
				title: "Full Time Jobs",
			},
			{
				label: "Night Shift Jobs",
				url: "/jobs/night_shift-jobs",
				title: "Night Shift Jobs",
			},
			{
				label: "International Jobs",
				url: `/international/jobs${
					isUserLoggedIn() ? `?c_id=${isUserLoggedIn()}` : ""
				}`,
				title: "International Jobs",
			},
		],
		height: 200,
	},

	{
		page: "Jobs By City",
		links: [
			{
				label: "Jobs in Aurangabad",
				url: "/jobs/jobs-in-aurangabad",
				title: "Jobs in Aurangabad",
			},
			{
				label: "Jobs in Agra",
				url: "/jobs/jobs-in-agra",
				title: "Jobs in Agra",
			},
			{
				label: "Jobs in Mumbai",
				url: "/jobs/jobs-in-mumbai",
				title: "Jobs in Mumbai",
			},
		],
		height: 120,
	},
	{
		page: "Jobs By Department",
		links: [
			{
				label: "Sales & BD",
				url: "/jobs/dep_sales_bd-jobs",
				title: "Sales & BD",
			},
			{
				label: "Admin / Back Office / Computer Operator",
				url: "/jobs/dep_admin_back_office_computer_operator-jobs",
				title: "Admin / Back Office / Computer Operator",
			},
			{
				label: "Customer Support",
				url: "/jobs/dep_customer_support-jobs",
				title: "Customer Support",
			},
			{
				label: "Delivery / Driver / Logistics",
				url: "/jobs/dep_delivery_driver_logistics-jobs",
				title: "Delivery / Driver / Logistics",
			},
			{
				label: "Restaurant / Hospitality / Tourism",
				url: "/jobs/dep_restaurant_hospitality_tourism-jobs",
				title: "Restaurant / Hospitality / Tourism",
			},
			{
				label: "Marketing / Brand / Digital Marketing",
				url: "/jobs/dep_marketing_brand_digital_marketing-jobs",
				title: "Marketing / Brand / Digital Marketing",
			},
		],
		height: 200,
	},
	{
		page: "Jobs By Company",
		links: [
			{
				label: "Swiggy",
				url: "/jobs/swiggy-jobs",
				title: "Swiggy",
			},
			{
				label: "Zomato",
				url: "/jobs/zomato-jobs",
				title: "Zomato",
			},
			{
				label: "YelloW Brick Centre of Brick World",
				url: "/jobs/yellow_brick_centre_of_brick_world-jobs",
				title: "YelloW Brick Centre of Brick World",
			},
		],
		height: 120,
	},
	{
		page: "Jobs By Qualification",
		links: [
			{
				label: "10th Pass Jobs",
				url: "/jobs/10th_pass-jobs",
				title: "10th Pass Jobs",
			},
			{
				label: "12th Pass Jobs",
				url: "/jobs/12th_pass-jobs",
				title: "12th Pass Jobs",
			},
		],
		height: 70,
	},
	// {
	// 	page: "Others",
	// 	links: [
	// 		{
	// 			label: "Free Job Alert",
	// 			url: "/free-job-alerts",
	// 			title: "Free Job Alert",
	// 		},
	// 		{
	// 			label: "Download Apna app",
	// 			url: "/download-apna-app",
	// 			title: "Download Apna App",
	// 		},
	// 		{
	// 			label: "Blog",
	// 			url: "https://apna.co/career-central/",
	// 			title: "Blog",
	// 		},
	// 		{
	// 			label: "Contact Us",
	// 			url: "/contact-us",
	// 			title: "Contact Us",
	// 		},
	// 	],
	// },
];

export default SIDEBAR_DATA;
