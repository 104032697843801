import React from "react";

const OutlinedSearch = ({ height = "24", width = "24", color = "#827485", ...props }) => (
	<svg
		width={width}
		height={height}
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M16.6651 14.8978H15.7383L15.4098 14.5812C16.8176 12.9398 17.545 10.7004 17.1461 8.3203C16.5947 5.06086 13.873 2.45799 10.5882 2.05936C5.62577 1.44968 1.44935 5.62364 2.05939 10.5832C2.45826 13.866 5.06265 16.5862 8.32401 17.1372C10.7055 17.5359 12.9462 16.8089 14.5886 15.402L14.9054 15.7303V16.6565L19.8913 21.6395C20.3723 22.1202 21.1583 22.1202 21.6393 21.6395C22.1202 21.1588 22.1202 20.3732 21.6393 19.8925L16.6651 14.8978ZM9.62621 14.8978C6.70506 14.8978 4.34703 12.5412 4.34703 9.62174C4.34703 6.70231 6.70506 4.34566 9.62621 4.34566C12.5473 4.34566 14.9054 6.70231 14.9054 9.62174C14.9054 12.5412 12.5473 14.8978 9.62621 14.8978Z"
			fill={color}
		/>
	</svg>
);

export default OutlinedSearch;
