import * as React from "react";
const ResumeChecker = (props) => (
	<svg width="26" height="26" viewBox="0 0 26 26" fill="none" {...props}>
		<mask
			id="mask0_1961_25349"
			maskUnits="userSpaceOnUse"
			x="0"
			y="0"
			width="26"
			height="26"
		>
			<path d="M0.200012 0.192175H25.8V25.7922H0.200012V0.192175Z" fill="white" />
		</mask>
		<g mask="url(#mask0_1961_25349)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.3589 4.77728V2.80128L23.2731 4.77728H21.3589ZM19.8783 5.54146C19.8783 5.9635 20.2097 6.30563 20.6186 6.30563H25.0604C25.3598 6.30563 25.6297 6.11945 25.7443 5.83389C25.8589 5.54834 25.7956 5.21966 25.5838 5.0011L21.1421 0.416045C20.9303 0.197492 20.6119 0.132113 20.3353 0.250392C20.0587 0.368672 19.8783 0.647319 19.8783 0.956399V5.54146Z"
				fill="#005F3E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.78436 14.5205C4.78436 18.2133 7.77805 21.207 11.4709 21.207C15.1638 21.207 18.1575 18.2133 18.1575 14.5205C18.1575 10.8276 15.1638 7.8339 11.4709 7.8339C7.77805 7.8339 4.78436 10.8276 4.78436 14.5205ZM11.4709 19.7211C8.59869 19.7211 6.27027 17.3927 6.27027 14.5205C6.27027 11.6482 8.59869 9.3198 11.4709 9.3198C14.3432 9.3198 16.6716 11.6482 16.6716 14.5205C16.6716 17.3927 14.3432 19.7211 11.4709 19.7211Z"
				fill="#005F3E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.22418 13.9474C8.22418 15.741 9.67827 17.1951 11.4719 17.1951C13.2656 17.1951 14.7197 15.741 14.7197 13.9474C14.7197 12.1537 13.2656 10.6996 11.4719 10.6996C9.67827 10.6996 8.22418 12.1537 8.22418 13.9474ZM11.4719 15.6962C10.5061 15.6962 9.72315 14.9132 9.72315 13.9474C9.72315 12.9815 10.5061 12.1986 11.4719 12.1986C12.4378 12.1986 13.2207 12.9815 13.2207 13.9474C13.2207 14.9132 12.4378 15.6962 11.4719 15.6962Z"
				fill="#005F3E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.33132 19.6681C7.74599 19.7365 8.13827 19.46 8.20749 19.0506C8.38809 17.9825 9.32644 17.1697 10.4565 17.1697H12.4864C13.6164 17.1697 14.5548 17.9825 14.7354 19.0505C14.8046 19.46 15.1968 19.7365 15.6115 19.6681C16.0262 19.5998 16.3063 19.2125 16.237 18.8031C15.9365 17.0252 14.3756 15.6666 12.4864 15.6666H10.4565C8.56722 15.6666 7.00642 17.0252 6.70582 18.8031C6.6366 19.2125 6.91664 19.5998 7.33132 19.6681Z"
				fill="#005F3E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.3703 5.54127C9.3703 5.96332 9.70531 6.30545 10.1186 6.30545H17.6011C18.0144 6.30545 18.3494 5.96332 18.3494 5.54127C18.3494 5.11923 18.0144 4.77709 17.6011 4.77709H10.1186C9.70531 4.77709 9.3703 5.11923 9.3703 5.54127Z"
				fill="#005F3E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.3947 14.92C17.3947 15.3421 17.7212 15.6842 18.1241 15.6842H22.0145C22.4173 15.6842 22.7439 15.3421 22.7439 14.92C22.7439 14.498 22.4173 14.1558 22.0145 14.1558H18.1241C17.7212 14.1558 17.3947 14.498 17.3947 14.92Z"
				fill="#005F3E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10.7071 8.50253C10.7071 8.87182 11.0411 9.17119 11.4531 9.17119H21.9969C22.4089 9.17119 22.7429 8.87182 22.7429 8.50253C22.7429 8.13324 22.4089 7.83387 21.9969 7.83387H11.4531C11.0411 7.83387 10.7071 8.13324 10.7071 8.50253Z"
				fill="#005F3E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.8658 11.7215C15.8658 12.0908 16.2003 12.3902 16.613 12.3902H21.9961C22.4088 12.3902 22.7434 12.0908 22.7434 11.7215C22.7434 11.3523 22.4088 11.0529 21.9961 11.0529H16.613C16.2003 11.0529 15.8658 11.3523 15.8658 11.7215Z"
				fill="#005F3E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.06209 24.2863C7.9241 24.2863 7.81226 24.1739 7.81226 24.0353V21.0449C7.81226 20.6291 7.4767 20.292 7.06278 20.292C6.64885 20.292 6.31329 20.6291 6.31329 21.0449V24.0353C6.31329 25.0056 7.09624 25.7922 8.06209 25.7922H24.0511C25.0169 25.7922 25.7999 25.0056 25.7999 24.0353V5.46276C25.7999 5.26307 25.7209 5.07156 25.5803 4.93035L21.0834 0.412706C20.9429 0.271503 20.7523 0.192177 20.5535 0.192177H8.06209C7.09624 0.192177 6.31329 0.978735 6.31329 1.94904V10.4034C6.31329 10.8193 6.64885 11.1564 7.06278 11.1564C7.4767 11.1564 7.81226 10.8193 7.81226 10.4034V1.94904C7.81226 1.81041 7.9241 1.69806 8.06209 1.69806H20.243L24.3009 5.77464V24.0353C24.3009 24.1739 24.1891 24.2863 24.0511 24.2863H8.06209Z"
				fill="#005F3E"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M2.98864 24.0673C2.69484 24.3611 2.21854 24.361 1.92483 24.0673C1.63105 23.7736 1.63105 23.2973 1.92483 23.0035L6.83115 18.0972C7.12492 17.8034 7.12492 17.3271 6.83115 17.0333C6.53738 16.7396 6.06109 16.7396 5.76732 17.0333L0.861002 21.9396C-0.0203176 22.821 -0.0203176 24.2498 0.861002 25.1312C1.74228 26.0124 3.17115 26.0124 4.05245 25.1312L4.05247 25.1312L8.95879 20.2248C9.25256 19.931 9.25256 19.4547 8.95879 19.161C8.66502 18.8672 8.18872 18.8672 7.89496 19.161L2.98866 24.0673C2.98865 24.0673 2.98865 24.0673 2.98864 24.0673Z"
				fill="#005F3E"
			/>
		</g>
	</svg>
);

export default ResumeChecker;
